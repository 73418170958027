module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Oxfordon - Szkoła Języków Obcych - angielski, niemiecki, francuski ::: Bydgoszcz ::: www.oxfordon.pl', // Navigation and Site Title
  titleAlt: 'Oxfordon - Szkoła Języków Obcych', // Title for JSONLD
  description: 'Oxfordon - Szkoła Języków Obcych - angielski, niemiecki, francuski ::: Bydgoszcz ::: www.oxfordon.pl',
  headline: 'Oxfordon - Szkoła Języków Obcych - angielski, niemiecki, francuski ::: Bydgoszcz ::: www.oxfordon.pl', // Headline for schema.org JSONLD
  url: 'https://oxfordon.pl', // Domain of your site. No trailing slash!
  logo: '/logos/logo.png', // Used for SEO
  ogLanguage: 'pl_PL', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'Oxfordon', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Oxfordon', // Author for schemaORGJSONLD
  themeColor: '#1b1a57',
  backgroundColor: '#EBEDF2',
  twitter: '', // Twitter Username
  facebook: '', // Facebook Site Name
  googleAnalyticsID: 'UA-XXXXXX-X',
}
